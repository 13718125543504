@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(custom.css);
.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}

.bg-white {
  background-color: #3e3e42;
}
h1 {
  @apply lg:text-5xl text-3xl font-bold;
}
h2 {
  @apply lg:text-5xl text-3xl;
}
h3 {
  @apply lg:text-3xl text-2xl;
}
h4 {
  @apply lg:text-xl text-lg font-medium;
}

p {
  @apply text-[14px] leading-7 text-[#000000]  font-medium;
}

.myContainer {
  @apply container w-full px-3 lg:px-5  lg:w-[80%] mx-auto;
}

.Toastify__progress-bar-theme--dark {
  background: #3f7fae !important;
}
